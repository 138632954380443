import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import '../styles/Navbar.css';
import logo from '../img/LOGO.webp';

function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [username, setUsername] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => setIsSticky(window.scrollY > 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      const storedUsername = localStorage.getItem('username');

      if (token && storedUsername) {
        try {
          const decodedToken = jwtDecode(token);
          const expirationTime = decodedToken.exp * 1000;
          const currentTime = Date.now();

          if (currentTime >= expirationTime) {
            handleLogout();
          } else {
            setTimeLeft(expirationTime - currentTime);
            setUsername(storedUsername);
          }
        } catch (error) {
          console.error('Erro ao decodificar o token:', error);
          handleLogout();
        }
      } else {
        handleLogout();
      }
    };

    checkTokenExpiration();
    const intervalId = setInterval(checkTokenExpiration, 1000);
    return () => clearInterval(intervalId);
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setUsername('');
    setTimeLeft(null);
    navigate('/login');
  };

  const formatTime = (milliseconds) => {
    if (!milliseconds) return '00:00';
    const totalMinutes = Math.floor(milliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
      <div className="navbar-container">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <ul className="nav-menu">
          <li><Link to="/cadastro-comercial">Clientes</Link></li>
          <li><Link to="/cadastro-motorista">Motoristas</Link></li>
          <li><Link to="/importacao">Importação</Link></li>
          <li><Link to="/relatorio">Relatório</Link></li>
        </ul>
        <div className="user-actions">
          {username && (
            <div className="user-info">
              Logado como: {username} <br />
              <span>Tempo restante: {formatTime(timeLeft)}</span>
            </div>
          )}
          <button onClick={handleLogout} className="logout-button">Sair</button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;