// frontend/src/pages/Login.js
import React, { useState } from 'react';
import api from '../axios'; // Usando a instância configurada do axios
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { username, password });
      const { token } = response.data;

      // Armazena o token e o username no localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);

      setMessage('Login bem-sucedido');
      navigate('/importacao');
    } catch (error) {
      // Se for erro de login, exibe a mensagem de erro retornada pelo backend
      setMessage(error.response?.data?.error || 'Erro ao fazer login');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Faça seu login<span style={{color: '#f687b3'}}>.</span></h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="username" className="login-label">Username</label>
            <input
              type="text"
              id="username"
              className="login-input"
              placeholder="Insira seu username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="login-label">Senha</label>
            <input
              type="password"
              id="password"
              className="login-input"
              placeholder="Insira sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Entrar</button>
        </form>
        {message && <p className={`login-message ${message.includes('Erro') ? 'error' : 'success'}`}>{message}</p>}
        <div className="copyright-info">
          <p>&copy; 2024 Gratidão Empreendimentos</p>
          <p>Desenvolvido por <a href="https://sonho.digital" target="_blank" rel="noopener noreferrer">SonhoDigital</a></p>
          <p>Versão 0.9.11</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
