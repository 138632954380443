// frontend/src/services/apiService.js

import axios from 'axios';

// URL base da API definida no .env
const API_URL = process.env.REACT_APP_API_URL;

// Adicionar token de autenticação
const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const createCliente = async (clienteData) => {
  try {
    const response = await axios.post(`${API_URL}/clientes`, clienteData, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao criar cliente:', error);
    throw error;
  }
};

export const getClientes = async () => {
  try {
    const response = await axios.get(`${API_URL}/clientes`, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao obter clientes:', error);
    throw error;
  }
};

// Função atualizada para buscar registros de importação com paginação
export const getImportacoes = async ({ limit, offset, status, startDate, endDate, sortField, sortDirection, teams }) => {
  try {
    const params = {
      limit,
      offset,
      status: Array.isArray(status) ? status.join(',') : status, // Certificar que status é string
      startDate: startDate ? new Date(startDate).toISOString() : undefined, // Convertendo para ISO
      endDate: endDate ? new Date(endDate).toISOString() : undefined, // Convertendo para ISO
      sortField,
      sortDirection,
      teams: Array.isArray(teams) ? teams.join(',') : teams, // Adicionando o parâmetro teams
    };

    console.log('Parâmetros enviados:', params);

    const response = await axios.get(`${API_URL}/importacao`, {
      ...getAuthHeaders(),
      params,
    });
    console.log('Resposta da API:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar registros de importação:', error);
    throw error;
  }
};

// Função para atualizar um registro de importação
export const updateImportacao = async (id, updatedData) => {
  try {
    const response = await axios.put(`${API_URL}/importacao/${id}`, updatedData, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar registro de importação:', error);
    throw error;
  }
};

// Função para criar um novo registro de importação
export const createImportacao = async (newData) => {
  try {
    const response = await axios.post(`${API_URL}/importacao`, newData, getAuthHeaders());
    return response.data;
  } catch (error) {
    console.error('Erro ao criar registro de importação:', error);
    throw error;
  }
};