import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Intercepta as respostas
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    // Verifica se é um erro 401 (token expirado ou inválido)
    if (error.response && error.response.status === 401) {
      if (error.response.data.message === 'Token expirado') {
        // Mostra o alerta de sessão expirada
        alert('Sua sessão expirou. Faça login novamente.');

        // Remove o token do localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('username');

        // Redireciona para a página de login
        window.location.href = '/login';
      } else if (error.response.data.message === 'Token inválido') {
        // Caso o token seja inválido por outro motivo, faça algo similar
        alert('Token inválido. Faça login novamente.');

        // Remove o token do localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('username');

        // Redireciona para a página de login
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;
